/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html, #root {
  height: 100%;
  width: 100%;
  cursor: url('./assets/images/leafmouse.png'), auto; /* Custom cursor */
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Fun font */
  background-color: #f4f9f4; /* Light green background */
  color: #333;
}

/* Home Container Styling */
.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

/* Centered Banner Image at Top Middle */
.banner-image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* Reduce width to 50% of the page */
  max-width: 200px; /* Set a maximum width to avoid it becoming too large */
  height: auto; /* Maintain aspect ratio */
  z-index: 2;
  padding: 0; /* Remove padding if any */
  object-fit: contain; /* Ensure the image fits nicely without distortion */
}

/* Background GIF Styling */
.background-gifs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: 320px;
  overflow: hidden;
  pointer-events: none;
}

.background-gifs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Header Styling */
.header {
  background-color: rgba(76, 175, 80, 0.9); /* Semi-transparent modern green */
  padding: 20px;
  text-align: center;
  color: white;
  backdrop-filter: blur(5px);
  position: relative;
}

.header h1 {
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.5); /* Add shadow for fun effect */
}

/* Navigation Styling */
.nav {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  backdrop-filter: blur(5px);
  border: 2px dashed #4caf50; /* Quirky border */
}

.dropbtn:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  border-radius: 5px;
  z-index: 1000; /* Increase z-index to ensure it appears above other elements */
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  backdrop-filter: blur(5px);
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none; /* Remove underline */
  display: block;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-content a:last-child {
  border-bottom: none;
}

.dropdown-content a:hover {
  background-color: #4caf50;
  color: white;
}

/* Show dropdown when open */
.dropdown .dropdown-content {
  opacity: 1;
  visibility: visible;
}

/* Sound Controls Styling */
.sound-controls {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
}

.sound-toggle {
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
  font-size: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
}

.sound-toggle:hover {
  background: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

/* Main Content Styling */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  position: relative;
  z-index: 1;
}

/* Hero Section */
.hero-section {
  /* Removed the background property */
  color: white;
  padding: 60px 40px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.hero-section h2,
.hero-section p,
.hero-section a {
  position: relative;
  z-index: 1;
}

.hero-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.hero-section h2 {
  font-size: 48px;
  margin-bottom: 20px;
  text-decoration: underline; /* Underline for fun */
}

.hero-section p {
  font-size: 20px;
  margin-bottom: 30px;
  font-style: italic; /* Italic for quirkiness */
}

.explore-button {
  background-color: #ff9800;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 50px;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

.explore-button:hover {
  background-color: #fb8c00;
  transform: translateY(-3px);
}

/* Features Section */
.features-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 60px;
  justify-content: center;
}

.feature-card {
  text-decoration: none;
  color: inherit;
  background: rgba(255, 255, 255, 0.85);
  padding: 30px 20px;
  border-radius: 10px;
  width: 280px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.feature-card h3 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #4caf50;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2); /* Shadow for a pop effect */
}

.feature-card p {
  font-size: 16px;
  color: #555;
}

.feature-card a {
  color: #ff9800; /* Modern link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease;
}

.feature-card a:hover {
  color: #fb8c00;
}

/* Feature Card Image Styling */
.feature-card img {
  width: 100%;
  height: 180px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* Call to Action Section */
.cta-section {
  background-color: rgba(76, 175, 80, 0.85);
  color: white;
  padding: 40px 20px;
  border-radius: 10px;
  text-align: center;
  margin-top: 60px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.cta-section h3 {
  font-size: 36px;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 20px;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #ff9800;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 50px;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

.cta-button:hover {
  background-color: #fb8c00;
  transform: translateY(-3px);
}

/* Footer */
.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

/* src/App.css */

/* Main about content styles */
.about-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

/* Image Section */
.about-images {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.about-image {
  max-width: 45%;
  border-radius: 8px;
}

/* Box for Description */
.about-description-box {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.about-description h2 {
  font-size: 24px;
  color: #333;
}

.about-description p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

/* About Section */
.team-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}

.team-member {
  text-align: center;
  position: relative;
}

.team-member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}

.team-member-image:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.team-member p {
  font-weight: bold;
  margin-top: 10px;
  font-size: 16px;
}

/* About Page Background */
.about-container {
  background-image: url('./assets/images/gddgd.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Optional: Keeps the background fixed while scrolling */
  min-height: 100vh; /* Ensures the background covers the entire page */
  color: white; /* Adjust text color to ensure it's visible on the background */
}

/* Fun Font and Bold Text Style */
.about-container h1,
.about-container h2,
.about-container p {
  font-family: 'Poppins', sans-serif; /* Fun font */
  font-weight: bold;
  font-size: 2rem; /* Larger font for visibility */
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.7); /* Stronger shadow effect */
  color: #f8f9fa; /* Light gray-white for high contrast */
}

/* Optional: Adjust text sizes for different elements */
.about-container h1 {
  font-size: 3rem; /* Large size for main title */
}

.about-container h2 {
  font-size: 2rem; /* Smaller size for subtitles */
}

.about-container p {
  font-size: 1.25rem; /* Adjust paragraph size */
}

/* Optional: Add a glow effect or other fun effects */
.about-container p:hover {
  color: #ffcc00; /* Change color on hover */
  text-shadow: 2px 2px 10px yellow;
}

.dropdown-content a,
.dropdown-content button {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.dropdown-content button:hover,
.dropdown-content a:hover {
  background-color: #4caf50;
  color: white;
}

.hero-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-size: cover; 
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.hero-image.visible {
  opacity: 1;
}

.fade-out {
  animation: fadeOut 3s ease-in forwards;
  animation-delay: 13s; /* Delay to match the GIF duration */
  opacity: 1; /* Keep opacity at 1 until the fade-out starts */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.about-description {
  background-color: rgba(0, 0, 0, 0.6); /* Transparent black background */
  color: #ffffff; /* Ensure text is white for contrast */
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}


